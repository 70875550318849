/* App.css */
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #1a1a2e;
  padding: 20px;
  
}

.dm-sans-normal {
 
  display: 'block';
  margin-bottom: '20px';
  
}

.title {
  font-size: 5rem;
  margin-top: 80px;
  color: #ffffff;
  text-align: center;
  margin-right: 200px;
  margin-left: 200px;
  margin-bottom: 0px;

  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.image {
  width: 600px; /* Increased width */
  height: auto;
}


.wafi {
  font-size:30px;
  color: #ffffff;
  margin: 10px 0;
  text-align: left;
  padding-right: 1100px;
}
.subheading1 {
  font-size:20px;
  color: #cecccc;
  margin: 10px 0;
  text-align: center;
  margin-bottom: 40px;
}

.get-started-btn {
  background-color: #1e88e500;
  color: #ffffff;
  font-size: 20px;
  padding: 15px 15px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border: #ffffff 2px solid;
  margin-top: px;
  margin-bottom: 100px;
  
}



.get-started-btn:hover {
  background-color: #333;
}

.wrapper{
  margin-top: -50px; /* Move this section further up */

}
.first-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -50px; /* Move this section further up */
  padding: 0 20px;
}
.subtitle {
  font-size: 1.25rem;
  color: #ffffff;
  margin: 10px ; /* Reduce the margin to move it up */
  text-align: center;
  padding-bottom:25px;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
  width: 100%;
  max-width: 1200px;
  
}

.info-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1d1d;
  padding: 40px;
  border-radius: 40px;
  border-style: solid;
  
  border: 1px solid #333;
}

.info-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-image {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
}

.info-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 40px;

}

.info-title {
  font-size: 2.5rem;
  color: #e8e8e8;
  margin-bottom: 20px;
  color: #ffffff;

}

.info-description {
  font-size: 1.1rem;
  color: #a9a9a9;
  margin-bottom: 30px;
  line-height: 1.6;
}

.read-more-btn {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read-more-btn:hover {
  background-color: #333;
}

.faq-section, .cta-section {
  width: 100%;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  align-items: center;
}

.cta-section{
    width: 100%;
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    color: #1a1a2e;
  
}
.section-title {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
}

.faq-list {
  background: #1d1d1d;
  border-radius: 10px;
  overflow: hidden;
}

.faq-item {
  border-bottom: 1px solid #2a2a3e;
  color: #1a1a2e;
}

.faq-question {
  width: 100%;
  padding: 20px;
  background: none;
  border: none;
  text-align: left;
  color: #e8e8e8;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  padding: 0 20px 20px;
  color: #a9a9a9;
}

.cta-button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 15px 30px;
  font-size: 1.1rem;
  color: white;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #333;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #0c0c0c;
  color: white;
  overflow: hidden;
}

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px; /* Adjust as needed */
}

.circle {
  width: 150px; /* Adjust circle size as needed */
  height: 150px; /* Adjust circle size as needed */
  background-color: white;
  border-radius: 50%;
  margin: 20px; /* Adjust spacing between circles as needed */
}


.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.image-container {
  width: 100%;
  max-width: 500px; /* Adjust this to match your previous image width */
  padding: 20px; /* Adjust this to match your previous image padding */
}

.circle-svg {
  width: 100%;
  height: auto;
  display: block;
  padding-top: 200px;
  padding-bottom: 100px;

}

.engineers-section {
  text-align: center;
  margin: 2rem 0;
  padding: 1rem;
  color: white;
}


.engineers-section .section-title {
  font-size: 2rem;
  color: #C7A5A5;
  margin-bottom: 20px;

}

.company-logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-top: 50px;
  padding-bottom: 100px;
}

.company-logo {
  font-weight: 900; /* Extra bold */
  font-size: 1.5rem;
  color: white; /* Matt black */
  text-transform: uppercase;
  letter-spacing: 1px;
}


@media (max-width: 400px) {
  .page-wrapper {
    padding: 10px;
  }

  .title {
    font-size: 2rem;
    margin: 20px 10px;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .wafi {
    font-size: 20px;
    padding-right: 20px;
  }

  .subheading1 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .get-started-btn {
    font-size: 16px;
    padding: 10px 10px;
    margin-bottom: 50px;
  }

  .wrapper {
    margin-top: -20px;
  }

  .subtitle {
    font-size: 1rem;
    padding-bottom: 15px;
  }

  .info-block {
    flex-direction: column;
    padding: 20px;
  }

  .info-title {
    font-size: 1.2rem;
  }

  .info-description {
    font-size: 0.9rem;
  }

  .faq-section, .cta-section {
    max-width: 100%;
    padding: 10px;
  }

  .circle {
    width: 80px;
    height: 80px;
  }

  .circle-svg {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .engineers-section .section-title {
    font-size: 1.5rem;
  }

  .company-logo {
    font-size: 1rem;
  }
}
