.email-form {
    width: 50%;
    max-width: 600px;
    background-color: #181818;
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .email-form label {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 15px;
  }
  
  .email-form input,
  .email-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    background-color: #0c0c0c;
    color: white;
  }
  
  .email-form input::placeholder,
  .email-form textarea::placeholder {
    color: #ffffff;
  }
  
  .email-form button {
    align-items: center;
    padding: 10px 20px;
    font-size: 1rem;
    color: rgb(0, 0, 0);
    background-color: #fff8f8;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .email-form button:hover {
    background-color: #333;
  }
  
  .form-success {
    text-align: center;
    font-size: 1.5rem;
    color: #ffffff;
    padding: 20px;
  }
  